import { get, servicesAPI } from 'supporticon/utils/client'

export const signIn = data =>
  servicesAPI.post('/v1/justgiving/iam/login', data)
    .then(response => response.data)

export const signUp = data =>
  servicesAPI.post('/v1/justgiving/iam/register', data)
    .then(response => response.data)

export const resetPassword = data =>
  servicesAPI.post('/v1/justgiving/iam/reset-password', data)

export const checkAccountAvailability = email =>
  get(`/v1/account/${email}`)
