import React from 'react'
import TraitsProvider from 'constructicon/traits-provider'
import { Provider } from 'react-redux'
import { Router, createMemoryHistory, useRouterHistory } from 'react-router'
import { updateClient } from 'supporticon/utils/client'
import { createHistory } from 'history'
import configureStore from './store'
import routes from './routes'
import * as traits from '../../../common/traits'

const isServer = () => typeof window === 'undefined'

const RegistrationApp = config => {
  const store = configureStore(config)

  updateClient({
    baseURL: config.servicesApiUrl
  })

  const routerOptions = {
    basename: '/users',
    entries: [ config.path ]
  }

  const history = isServer()
    ? createMemoryHistory(routerOptions)
    : useRouterHistory(createHistory)(routerOptions)

  return (
    <div id='mount'>
      <Provider store={store}>
        <TraitsProvider traits={traits}>
          <Router history={history}>{routes}</Router>
        </TraitsProvider>
      </Provider>
    </div>
  )
}

export default RegistrationApp
